<template>
    <div style="position: relative; min-height: 449px">
        <textarea v-model.lazy="content" ref="editor" :id="$data._id" :name="name" :rows="rows" cols="cols" @change="input" style="width: 100%; display: none">{{ value }}</textarea>
    </div>
</template>

<script>
let uuid = 0;
export default {
    props: {
        id: { type: String, default: null },
        name: { type: String, default: undefined },
        rows: { type: String, default: "10" },
        cols: { type: String, default: "100" },
        value: { type: String, default: "" },
        bSkipXssFilter: { type: Boolean, default: false },
    },
    data() {
        return {
            _id: this.$props.id || `ir${uuid}`,
            oEditors: [],
            content: "",

            omitsEmit: false,
        };
    },
    methods: {
        input() {
            this.omitsEmit = true;
            this.$emit("input", this.content);
        },
        setShows(showsImageUploader) {
            this.showsImageUploader = showsImageUploader;
        },
    },
    watch: {
        value() {
            if (this.omitsEmit) return;

            this.content = this.value;

            // HTML 업데이트
            if (this.bSkipXssFilter) {
                this.oEditors?.getById?.[this?.$data?._id]?.exec("SET_IR", [""]);
                this.oEditors?.getById?.[this?.$data?._id]?.exec("PASTE_HTML", [this.content]);
            }
        },
        omitsEmit() {
            if (this.omitsEmit) {
                setTimeout(() => (this.omitsEmit = false), 100);
            }
        },
    },
    beforeCreate() {
        uuid += 1;
    },
    mounted() {
        const { bSkipXssFilter } = this;

        this.content = this.value;

        nhn.husky.EZCreator.createInIFrame({
            oAppRef: this.oEditors,
            elPlaceHolder: this.$data._id,
            sSkinURI: "/js/smarteditor2/SmartEditor2Skin.html",
            fCreator: "createSEditor2",
            fOnAppLoad: () => {
                //기존 저장된 내용의 text 내용을 에디터상에 뿌려주고자 할때 사용
                try {
                    // this.oEditors.getById[this._id].exec("PASTE_HTML", [this.value]);
                    var contentElement = this.oEditors.getById[this.$data._id].elEditingAreaContainer.children[0].contentWindow.document.getElementsByClassName("se2_inputarea")[0];
                    if (contentElement) {
                        contentElement.addEventListener("focusout", () => {
                            this.oEditors.getById[this.$data._id].exec("UPDATE_CONTENTS_FIELD", []);

                            var event = document.createEvent("HTMLEvents");
                            event.initEvent("change", false, true);
                            this.$refs.editor.dispatchEvent(event);
                        });
                    }
                } catch (error) {
                    console.error("[SmartEditor]-fOnAppLoad");
                    console.error(error);
                }
            },
            htParams: {
                bSkipXssFilter,
                SE2M_FontName: {
                    htMainFont: { id: "Nanum Gothic", name: "나눔고딕", url: "", cssUrl: "" }, // 기본 글꼴 설정
                },
                aAdditionalFontList: [
                    // ["Nanum Gothic", "나눔고딕"],
                    ["Nanum Myeongjo", "나눔명조"],
                    ["Nanum Barun Gothic", "나눔바른고딕"],
                    ["Nanum Square", "나눔스퀘어"],
                    ["Maru Buri", "마루부리"],
                    ["Restart", "다시 시작해"],
                    ["Bareun Hipi", "바른히피"],
                    ["Daughter", "우리딸 손글씨"],
                ], // 추가 글꼴 목록
            },
        });
    },
};
</script>
